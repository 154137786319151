.HomePage {
  // Spacing around sections.
  // `row-gap` will add even space automatically between each homepage section.
  // All components added to sections need to have 0 margin top and bottom.

  display: flex;
  flex-direction: column;
  margin: 2.5rem 0;
  row-gap: 3rem;

  @include media-breakpoint-up(sm) {
    row-gap: 3.5rem;
  }

  @include media-breakpoint-up(md) {
    row-gap: 4rem;
  }

  @include media-breakpoint-up(xxl) {
    row-gap: 4.5rem;
  }

  .ExtensionMidPageBanner {
    margin-bottom: 0;
  }

  .RecentlyViewedSection {
    margin-bottom: 10px;
  }

  .fan-favourites {
    margin-bottom: 10px; // Height of carousel dots on mobile

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .HomePage__nonGames {
    background-color: var(--site-background-color-secondary);
    padding: 3rem 0;

    @include theme(light) {
      background-color: $white;
    }

    @include media-breakpoint-up(sm) {
      padding: 3.5rem 0;
    }

    @include media-breakpoint-up(md) {
      padding: 4rem 0;
    }

    @include media-breakpoint-up(xxl) {
      padding: 4.5rem 0;
    }

    .ProductTabsSection {
      margin-bottom: 0;
    }

    .ProductCardCarousel {
      margin-bottom: 0;
    }
  }
}

.homepage {
  position: relative;

  .flash-deals-section-carousel {
    .section-heading-container-between {
      h2,
      h4 {
        margin: 0;
      }
    }
  }

  .current-deals-container {
    padding-top: 0.75rem;

    .section-heading-container {
      justify-content: space-between;
      flex-direction: column;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }

      h4 {
        margin-bottom: calc(0.5rem - 5px);
      }

      .nextdeal-countdown-container {
        display: flex;
        font-size: 18px;

        .countdown {
          margin-left: 0.5rem;

          .digit-container {
            font-weight: 700;
          }

          .colon {
            margin: 0 2px;
          }
        }
      }
    }
  }

  .trending-carousel-title {
    margin-top: 2rem !important;
  }

  .homepage-ads-top-container {
    @include media-breakpoint-up(xs) {
      min-height: 653px;
    }

    @include media-breakpoint-up(sm) {
      min-height: 900px;
    }

    @include media-breakpoint-up(md) {
      min-height: 714px;
    }

    @include media-breakpoint-up(lg) {
      height: 392px;
      min-height: 0;
    }

    @include media-breakpoint-up(xl) {
      height: 458px;
    }

    @include media-breakpoint-up(xxl) {
      height: 540px;
    }
  }

  .SaleButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .secondary-ad-container {
    display: flex;
    justify-content: center;
    height: 100%;

    a {
      flex-grow: 1;
    }
  }

  @include media-breakpoint-only(md) {
    .home-coming-soon,
    .home-new-releases,
    .home-latest-deals.latest-deals-count-4 {
      .HitCardContainer:nth-child(4) {
        display: none;
      }
    }
  }

  .home-latest-deals {
    .notification-banner {
      margin-bottom: 1rem;

      .banner-content {
        padding: 0 0.5rem;
      }
    }
  }

  .email-subscribe-container {
    .email-subscribe-box-large {
      margin-bottom: 1rem;

      @include media-breakpoint-only(md) {
        margin-bottom: 2rem;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 3rem;
      }
    }
  }

  .trending-deals-two-row-carousel {
    padding-bottom: 3rem;

    .desktop-carousel {
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .medium-carousel {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .mobile-carousel {
      display: block;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .two-row-carousel {
      .top-row {
        margin: 0 -15px 1.25rem;

        @include media-breakpoint-up(md) {
          margin: 0 -15px 1.75rem;
        }

        @include media-breakpoint-up(xl) {
          margin: 0 -15px 2.5rem;
        }
      }

      .bottom-row {
        margin-right: -15px;
        margin-left: -15px;
      }

      .arrow-btn-container {
        top: 35px;

        @include media-breakpoint-up(lg) {
          top: 25px;
        }
      }

      .HitCardContainer {
        padding: 0 15px;
      }
    }
  }

  .more-great-deals-container {
    // margin-bottom: 0.5rem;

    .slick-dots {
      margin-top: calc(0.5rem + 6px);
    }
  }

  .flash-deals-carousel {
    margin-bottom: 2rem !important;
    padding-top: 0.75rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 3rem !important;
    }
  }

  .omen-optimizer-banner {
    margin-bottom: -1rem;
  }

  .omen-free-games-banner {
    margin-bottom: -1rem;
  }

  .fake-footer-banner {
    min-height: 380px;

    @include media-breakpoint-up(md) {
      padding: 0;
      min-height: 180px;
    }

    @include media-breakpoint-up(lg) {
      height: 200px;
    }

    @include media-breakpoint-up(xl) {
      height: 220px;
    }

    @include media-breakpoint-up(xxl) {
      height: 260px;
    }
  }
}

// BLOG SECTION

.homepage-blog-section {
  .blog-post-card {
    @include theme(light) {
      box-shadow: var(--card-box-shadow);
    }
  }
}

// WISHLIST SECTION

.homepage-wishlist-section {
  background-color: var(--site-background-color-secondary);
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
  margin-top: 2rem;

  .section-heading-container {
    justify-content: space-between;
  }

  .product-slider {
    margin-bottom: 3rem;
  }

  .wishlist-upsell {
    text-align: center;
    font-size: 15px;

    a {
      color: var(--site-font-color);
      text-decoration: underline;

      &:hover {
        color: var(--brand-core, $primary-core);
      }
    }
  }

  .wishlist-sale-secondary-text {
    display: none;
    color: $font-color-muted;
    font-size: 15px;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
    }
  }

  .HitCard:hover,
  .hit-mega-card:hover {
    .hit-card-overlay,
    .top-product-overlay {
      background-color: rgba($black, 0.92);
    }
  }
}

.user-recommendations-carousel-section {
  .slick-dots {
    @include media-breakpoint-down(sm) {
      display: none !important;
    }
  }
}

// ProductTabsSection

.ProductTabsSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .ProductTabsSection__viewLink {
    flex-shrink: 0;
    color: var(--brand-core, $primary-core);
    border: 1px solid var(--brand-core, $primary-core);
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    text-transform: uppercase;
    transition: all 0.15s ease-in-out;
    font-size: 0.875rem;
    line-height: 1.75;

    @include media-breakpoint-up(xxl) {
      font-size: 0.9375rem;
    }

    &:hover {
      // text-decoration: underline;
      background-color: var(--brand-core, $primary-core);
      color: $text-black;
    }
  }

  .ProductTabsSection__titleView {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: var(--gutter-gap);

    h2 {
      margin-bottom: 0;
    }
  }

  .ProductTabsSection__tabsView {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: var(--gutter-gap);

    @include media-breakpoint-only(xs) {
      margin-left: -1rem;
      margin-right: -1rem;
    }

    .ProductTabsSection__tabs {
      display: flex;
      gap: 0.5rem;
      width: 100%;
      overflow-x: scroll;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;  /* Firefox */
      -ms-overflow-style: none;  /* IE and Edge */

      &::-webkit-scrollbar {
        display: none;
      }

      @include media-breakpoint-only(xs) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      @include media-breakpoint-up(lg) {
        gap: 1rem;
      }

      .ProductTabsSection__tabs__tab {
        flex-shrink: 0;
        height: 30px;
        background-color: var(--card-background-color);
        color: var(--site-font-color);
        padding: 0 0.75rem;
        border-radius: 15px;
        white-space: nowrap;
        font-weight: 400;
        border: none;
        // font-size: 12px;
        font-size: var(--site-font-size-small);

        &--active {
          background-color: $white;
          color: $text-black;

          @include theme(light) {
            background-color: $grey-3;
          }
        }

        &:hover {
          background-color: $white;
          color: $text-black;
        }

        @include theme(light) {
          border: 1px solid $grey-3;

          &:hover {
            background-color: $grey-1;
          }
        }
      }
    }
  }
}
